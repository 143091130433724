import React, { FC } from 'react';

import { Sections } from '@/components/sections/Section';
import { GroupSection } from '@/components/sections/group/Group';
import { Groups, Wrapper } from './styles';
import { ContentfulSection } from '../types.d';

export interface FooterSection extends ContentfulSection {
  columns?: GroupSection[];
}

/**
 * Footer section
 */
const Footer: FC<FooterSection> = ({ columns = [] }) => (
  <Wrapper>
    <div className="container">
      <Groups>
        <Sections ignoreContainer sections={columns} />
      </Groups>
    </div>
  </Wrapper>
);

export default Footer;
