import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { rem } from '@/app/common/style/functions';
import { desktopDown, tabletDown } from '@/app/common/style/mixins';
import { COLOR, FONTS } from '@/app/common/style/variables';

export const Wrapper = styled.section`
  margin: 0;
  background-color: ${COLOR.woodSmoke1};
  padding: 56px 0 140px 0;

  ${tabletDown(css`
    padding-top: 48px;
    padding-bottom: 48px;
  `)}
`;

export const Groups = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -40px;

  > .contentful-section-group {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0 40px;

    > .contentful-section-links {
      a {
        font-size: ${rem(30)};
        line-height: 42px;
        letter-spacing: 0.5px;
        text-decoration: none;
        color: ${COLOR.white};
        font-weight: ${FONTS.light};
        position: relative;
        transition: 0.2s color ease;

        &:hover,
        &:focus {
          color: ${COLOR.woodSmoke3};
        }
      }
    }

    > .contentful-card {
      h4 {
        text-transform: uppercase;
        font-size: ${rem(14)};
        font-weight: ${FONTS.regular};
        line-height: 24px;
        display: block;
        color: ${COLOR.woodSmoke4};
        letter-spacing: 2px;
        margin-bottom: 8px;
        position: relative;
      }

      .card-content {
        > div {
          margin-top: 8px;
          position: relative;

          p {
            font-size: ${rem(16)};
            text-decoration: none;
            color: ${COLOR.white};
            font-weight: ${FONTS.regular};
            line-height: 26px;
            letter-spacing: 0.2px
            position: relative;
          }
        }
      }
    }
  }

  ${desktopDown(css`
    margin: 0 -24px;

    > .contentful-section-group {
      padding: 0 24px;

      > .contentful-card {
        h4 {
          margin-bottom: 8px;
        }

        .card-content {
          > div {
            p {
              font-size: ${rem(14)};
              line-height: 22px;
              position: relative;
            }
          }
        }
      }
    }
  `)}

  ${tabletDown(css`
    flex-direction: column;
    margin: 0;

    > .contentful-section-group {
      padding: 0;

      > .contentful-card {
        padding-bottom: 40px;

        .card-content {
          > div {
            display: flex;
            flex-direction: row;
            margin: 0 -24px;

            p {
              width: 50%;
              margin: 0 24px;
            }
          }
        }
      }

      &.tablet-down-margin-bottom-100 {
        margin-bottom: 100px;
      }
    }
  `)}
`;
